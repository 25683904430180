import axios from '@/utils/request'

/**
 * 查询试卷信息
 * @param data 查询参数
 * @returns 返回试卷查询结果
 */
export function getWordPaperPage(data) {
  return axios({
    url: '../word/api/v1/student-word-paper-log/list',
    params: data,
    method: 'get'
  })
}

/**
 * 重置试卷
 * @param data 试卷ID
 * @returns 返回重置结果
 */
export function reset(data) {
  return axios({
    url: '../word/api/v1/student-word-paper-log/reset/' + data,
    method: 'put',
  })
}

/**
 * 统计试卷相关数据
 * @param data 统计参数
 * @returns 返回统计结果
 */
export function getStatis(data) {
  return axios({
    url: '../word/api/v1/student-word-paper-log/statis-detail',
    params: data,
    method: 'get'
  })
}

/**
 * 获取词汇模块听写练习题目分页记录
 * @param data 查询参数
 * @returns 返回题目分页记录
 */
export function getModuleStudyList(data) {
  return axios({
    url: '../word/api/v1/student-word-paper-log/word-exercise-module',
    data: data,
    method: 'post'
  })
}

/**
 * 模块重置
 * @param data 模块ID
 * @returns 返回重置结果
 */
export function resetModule(data) {
  return axios({
    url: '../word/api/v1/student-word-paper-log/resetModule/' + data,
    method: 'put',
  })
}

/**
 * 提交单词练习答案
 * @param data 答案数据
 * @returns 返回提交结果
 */
export function stuAnswer(data) {
  return axios({
    url: '../word/api/v1/student-word-paper-log',
    data: data,
    method: 'post'
  })
}

/**
 * 获取试卷分类下拉列表
 * @param data 查询参数
 * @returns 返回试卷分类列表
 */
export function getPaperSortList(data) {
  return axios({
    url: '../word/api/v1/word-paper-classify/select-list',
    method: 'get',
    params: data
  })
}