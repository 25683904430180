<template>
  <div class="words">
    <!-- 顶部标签栏 -->
    <a-row class="tabBar block">
      <a-col :span="6" class="tabBarItem">
        <div class="name">抽查词库</div>
        <div class="num">{{statis.word_count}}</div>
      </a-col>
      <a-col :span="6" class="tabBarItem">
        <div class="name">攻坚剩余</div>
        <div class="num">{{statis.word_count-statis.master_count-statis.sweep_count}}</div>
      </a-col>
      <a-col :span="6" class="tabBarItem">
        <div class="name">复习掌握</div>
        <div class="num">{{statis.master_count}}</div>
      </a-col>
      <a-col :span="6" class="tabBarItem">
        <div class="name">扫荡生词</div>
        <div class="num">{{statis.sweep_count}}</div>
      </a-col>
    </a-row>
    <!-- 搜索与列表区域 -->
    <div class="block tableBox">
      <!-- 搜索框 -->
      <div class="boxSearch">
        <a-form-model layout="inline">
          <a-form-model-item label="考试项目">
            <a-select placeholder="请选择考试项目" v-model="param.subject_type" @change="changeTable(1)" style="width:200px">
              <a-select-option value="">全部</a-select-option>
              <a-select-option v-for="(item,index) in subjectList" :key="index" :value="index+1">{{item}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="分类名称">
            <a-select placeholder="请选择分类名称" v-model="param.word_paper_classify_id" @change="changeTable(1)" style="width:200px">
              <a-select-option value="">全部</a-select-option>
              <a-select-option v-for="(item,index) in sortList" :key="index" :value="item.id">{{item.name}}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
        <div style="display: flex;align-items: center;">
          <div style="display: flex;align-items: center;justify-content: space-between;margin-right: 10px;">
            <div>
              <a-input-search placeholder="请输入试卷名称" style="width:320px;" v-model="param.word_paper_name" @input="getData" />
            </div>
          </div>
        </div>
      </div>
      <!-- 试卷列表 -->
      <a-list class="listWrap" item-layout="horizontal" :data-source="list" :pagination="{total:total,onChange:changeTable,}" :loading="isLoading">
        <a-list-item class="listItem" slot="renderItem" slot-scope="item">
          <a-list-item-meta :description="item.word_paper_classify_name || '--'">
            <a slot="title" style="cursor:pointer;color:#1890ff" @click="$router.push({path:'/words/wordLib',query:{id:item.id}})">{{item.name}}</a>
          </a-list-item-meta>
          <div style="display:flex;width:300px;justify-content:space-between;align-items:center;">
            <div class="numItem">
              <div>抽查词库</div>
              <div>{{item.word_count }}</div>
            </div>
            <div class="numItem">
              <div>攻坚剩余</div>
              <div>{{item.word_count - item.master_count -item.sweep_count}}</div>
            </div>
            <div class="numItem">
              <div>复习掌握</div>
              <div>{{item.master_count }}</div>
            </div>
            <div class="numItem">
              <div>扫荡生词</div>
              <div>{{item.sweep_count }}</div>
            </div>
          </div>
        </a-list-item>
      </a-list>
    </div>
  </div>
</template>
<script>
import {
  getWordPaperPage,
  getPaperSortList,
  getStatis,
  reset,
} from "@/api/word";
import { subjectList, beforeRouteLeave } from "@/utils/common";
export default {
  data() {
    return {
      subjectList: JSON.parse(JSON.stringify(subjectList)), // 存储考试项目列表
      param: {
        // 查询参数
        subject_type: "",
        word_paper_classify_id: "",
        word_paper_name: "",
        page: 1,
        limit: 8,
      },
      sortList: [], // 存储试卷分类列表
      list: [], // 存储试卷列表
      total: 0, // 总数
      statis: {
        // 存储统计信息
        word_count: 0,
        master_count: 0,
        sweep_count: 0,
      },
      isLoading: false, // 加载状态
    };
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo; // 获取用户信息
    },
  },
  created() {
    this.getStatis(); // 初始化统计信息
    this.getData(); // 初始化查询
  },
  methods: {
    // 获取试卷分类下拉列表
    getPaperSortList() {
      getPaperSortList({
        campus_id: this.userInfo.campus_id,
        subject_type: this.param.subject_type,
      }).then((res) => {
        if (res.code == 200) {
          this.sortList = res.data;
        }
      });
    },
    // 获取统计信息
    getStatis() {
      getStatis().then((res) => {
        if (res.code == 200) {
          this.statis = res.data;
        }
      });
    },
    // 分页查询
    changeTable(page) {
      this.param.page = page;
      this.getData();
    },
    // 跳转到学习页面
    toStudy(item) {
      let num = 0;
      switch (item.type) {
        case 1:
          num = item.word_count;
          break;
        case 2:
          num = item.word_count - item.master_count - item.sweep_count;
          break;
        case 3:
          num = item.master_count;
          break;
        case 4:
          num = item.sweep_count;
          break;
      }
      if (!num) {
        this.$message.success("恭喜，您已完成此模块学习！");
        return;
      }
      this.$router.push({
        path: "/learn",
        query: { id: item.id, type: item.type, name: item.name },
      });
    },
    // 重置
    reset(id) {
      let _this = this;
      this.$confirm({
        title: "提示",
        content: "是否重置该项",
        onOk() {
          reset(id).then((data) => {
            if (data.code === 200) {
              _this.$message.success("重置成功");
              _this.getStatis();
              _this.getData();
            }
          });
        },
      });
    },
    // 查询试卷
    getData() {
      this.isLoading = true;
      getWordPaperPage(this.param).then((data) => {
        this.isLoading = false;
        if (data.code === 200) {
          this.list = data.data.map((item) => {
            return {
              ...item,
              type: 1,
            };
          });
          this.total = data.total;
        }
      });
    },
  },
  watch: {
    "param.subject_type": {
      handler(val) {
        if (val) {
          this.getPaperSortList(); // 当选择考试项目时，获取试卷分类列表
        }
      },
      immediate: true,
    },
  },
  beforeRouteLeave: beforeRouteLeave(["wordLib"]),
};
</script>

<style scoped lang="less">
.block {
  background: #fff;
  margin-bottom: 10px;
  padding: 24px;
}
.words {
  .tabBar {
    .tabBarItem {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      border-right: 1px solid #999;
      &:last-child {
        border: 0;
      }
      .name {
        color: #999;
      }
      .num {
        color: rgb(24, 144, 255);
        font-weight: 700;
        font-size: 30px;
      }
    }
  }
  .tableBox {
    .boxSearch {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .listWrap {
      margin-top: 10px;
      .listItem {
        .numItem {
          width: 100px;
          margin-right: 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        .option {
          flex: 1;
          text-align: right;
        }
      }
    }
  }
}
</style>